const Pie = ({ active }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 24C18.9624 24 24 18.9624 24 12.75L12.75 12.75L12.75 24ZM14.25 14.25L22.3857 14.25C21.7368 18.4277 18.4277 21.7368 14.25 22.3857L14.25 14.25ZM1.11464e-06 11.25C1.65774e-06 5.03761 5.03761 -1.65774e-06 11.25 -1.11464e-06C17.4624 -5.71536e-07 22.5 5.03761 22.5 11.25L11.25 11.25L11.25 22.5C5.03761 22.5 5.71536e-07 17.4624 1.11464e-06 11.25Z"
        fill={active ? "#2DA84F" : "#8DA0B3"}
      />
    </svg>
  );
};
export default Pie;
