const Youtube = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5469 7.19059C22.2932 6.33412 21.5502 5.65882 20.6078 5.42824C18.8863 5 12 5 12 5C12 5 5.11366 5 3.39208 5.41176C2.46787 5.64235 1.70675 6.33412 1.45305 7.19059C1 8.75529 1 12 1 12C1 12 1 15.2612 1.45305 16.8094C1.70675 17.6659 2.44975 18.3412 3.39208 18.5718C5.13178 19 12 19 12 19C12 19 18.8863 19 20.6078 18.5882C21.5502 18.3576 22.2932 17.6824 22.5469 16.8259C22.9999 15.2612 22.9999 12.0165 22.9999 12.0165C22.9999 12.0165 23.018 8.75529 22.5469 7.19059ZM15 12L10 9V15L15 12Z"
        fill="white"
      />
    </svg>
  );
};
export default Youtube;
