import React, { useState } from "react";

const Help = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM12 15.875C12.5523 15.875 13 16.3227 13 16.875V17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17V16.875C11 16.3227 11.4477 15.875 12 15.875ZM10.9068 8.27135C11.314 8.03203 11.7927 7.94455 12.2582 8.0244C12.7238 8.10425 13.146 8.34628 13.4502 8.70761C13.7543 9.06895 13.9208 9.52628 13.9201 9.99859L13.9201 10.0001C13.9201 10.4692 13.555 10.9583 12.8654 11.418C12.551 11.6276 12.2296 11.7891 11.9827 11.8988C11.8607 11.953 11.7605 11.993 11.693 12.0187C11.6594 12.0315 11.6342 12.0407 11.6188 12.0462L11.6028 12.0517C11.0796 12.2268 10.7969 12.7927 10.9714 13.3163C11.1461 13.8403 11.7124 14.1234 12.2363 13.9488L12.299 13.9269L12.2376 13.9483L12.2391 13.9478L12.243 13.9465L12.2541 13.9427L12.2888 13.9306L12.299 13.9269C12.3264 13.9171 12.3621 13.904 12.405 13.8877C12.5016 13.8509 12.6358 13.7972 12.795 13.7264C13.1107 13.5861 13.5392 13.3726 13.9748 13.0821C14.7851 12.542 15.9197 11.5314 15.9201 10.001C15.9214 9.05656 15.5884 8.14213 14.9802 7.41961C14.3719 6.69694 13.5274 6.21289 12.5963 6.05319C11.6653 5.89349 10.7078 6.06845 9.89339 6.54708C9.07898 7.02572 8.46025 7.77713 8.14678 8.66824C7.96351 9.18923 8.23728 9.76015 8.75827 9.94342C9.27926 10.1267 9.85018 9.85292 10.0335 9.33193C10.1902 8.88637 10.4996 8.51066 10.9068 8.27135Z"
        fill="#8DA0B3"
      />
    </svg>
  );
};
export default Help;
