const Twitter = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9647 8.50773C19.9647 8.33283 19.9611 8.15872 19.953 7.98555C20.7541 7.41549 21.4506 6.70415 22 5.89422C21.2647 6.21567 20.4735 6.43239 19.6435 6.53004C20.4907 6.03014 21.1411 5.23887 21.4478 4.29578C20.6549 4.75843 19.777 5.09492 18.8424 5.27612C18.0937 4.49108 17.0273 4 15.8473 4C13.581 4 11.7434 5.80894 11.7434 8.03886C11.7434 8.35591 11.7794 8.66405 11.8498 8.95983C8.4396 8.79107 5.4156 7.18373 3.392 4.73977C3.03928 5.33652 2.83632 6.03014 2.83632 6.76983C2.83632 8.17124 3.56072 9.4083 4.66224 10.132C3.98928 10.1116 3.35688 9.92954 2.80384 9.62667C2.80288 9.64352 2.80288 9.66046 2.80288 9.67817C2.80288 11.6346 4.21752 13.2677 6.09488 13.6381C5.75032 13.7303 5.3876 13.7801 5.0132 13.7801C4.74888 13.7801 4.49176 13.7544 4.24184 13.7073C4.76424 15.312 6.27896 16.4798 8.07512 16.5127C6.67048 17.596 4.90136 18.2416 2.97888 18.2416C2.64776 18.2416 2.3212 18.223 2 18.1857C3.81608 19.3313 5.97224 20 8.28984 20C15.8374 20 19.9647 13.8449 19.9647 8.50773Z"
        fill="white"
      />
    </svg>
  );
};
export default Twitter;
